// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getMedicalOrgsRates(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetLK_OGV', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMedicalOrgs(regionId) {
		let params = {
			filters: [
				{
					fieldName: 'RegionId',
					filterType: 1,
					filterValueType: 1,
					value1: regionId
				}
			],
			sortOption: {
				fieldName: 'RegionName',
				sortType: 2
			},
			pageSize: 999,
			currentPage: 0
		};
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
