import { render, staticRenderFns } from "./TableMain.vue?vue&type=template&id=42a77c28&scoped=true&"
import script from "./TableMain.vue?vue&type=script&lang=js&"
export * from "./TableMain.vue?vue&type=script&lang=js&"
import style0 from "./TableMain.vue?vue&type=style&index=0&id=42a77c28&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a77c28",
  null
  
)

export default component.exports