<template>
	<div class="w-full">
		<div class="flex justify-between">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Главная страница ЛК ОГВ</h1>
			<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
		</div>
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<!-- <div class="flex w-full h-auto mt-4 px-7 py-5 rounded-lg bg-white filter shadow-default"> -->
			<div class="w-full rounded-lg bg-white">
				<div class="t-overflow w-full rounded-lg" :style="medOrgs.length > 9 ? 'height: calc(100vh - 320px)' : ''">
					<table :style="medOrgs.length > 0 ? 'min-height: 600px' : 'min-height: 300px'">
						<thead>
							<!-- Заголовки -->
							<tr class="relative z-10" :class="{ 'tr1__sticky bg-gray-50': rowsVisible > 5 }">
								<th rowspan="2" class="w-20 custom-fs-12 border-b border-r border-gray-100 sticky left-0 z-index-1 bg-gray-50 text-gray-400 cursor-pointer font-normal text-center">
									П/П
								</th>
								<th rowspan="2" class="border-b border-r border-gray-100 sticky left-9 z-index-1 bg-gray-50">
									<p class="table-cell my-auto custom-fs-12 text-gray-400 font-normal relative" :class="{ 'text-blue-500 underline': filterBy === 0 }">
										<span class="cursor-pointer" @click="switchFilterVisibility(0)"> Наименование МО </span>
										<Icons v-if="params.filters.find((f) => f.fieldName === fields[0].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
										<!-- eslint-disable max-len -->
										<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
												fill="#9193A5"
											/>
										</svg>
										<!-- eslint-enable max-len -->
										<!-- Фильтр и сортировка -->
										<FilterComponent
											v-on:change="switchFilterVisibility(null)"
											position="left"
											:filterOpen="filterOpen"
											:id-filter="0"
											:param="params"
											@query="getData"
											:fields="fields"
											:item="fields[0]"
										/>
									</p>
								</th>
								<th colspan="4" class="text-center custom-fs-12 border-b border-r border-gray-100 py-2">Анкеты (интерактивные)</th>
								<th colspan="4" class="text-center custom-fs-12 border-b border-r border-gray-100 py-2">Анкеты (бумажные)</th>
								<th colspan="4" class="text-center custom-fs-12 border-b border-gray-100 py-5">Сайт</th>
							</tr>
							<tr :class="{ 'tr2__sticky bg-gray-50': rowsVisible > 5 }">
								<template v-for="(item, key) in fields">
									<th
										v-if="key !== 0"
										:key="'A' + key"
										:rowspan="key === 0 ? 2 : 1"
										class="border-b border-r border-gray-100 sticky left-0 z-index-1"
										:class="{ 'bg-white': key === 0 }"
									>
										<p
											class="table-cell my-auto text-gray-400 cursor-pointer font-normal justify-center relative custom-fs-12"
											:class="{ 'text-blue-500 underline': filterBy === key }"
										>
											<span @click="switchFilterVisibility(key)" :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
												{{ item.Name }}
											</span>
											<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
											<!-- eslint-disable max-len -->
											<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
													fill="#9193A5"
												/>
											</svg>
											<!-- eslint-enable max-len -->
											<!-- Фильтр и сортировка -->
											<FilterComponent
												v-on:change="switchFilterVisibility(null)"
												:position="key === 1 ? 'left' : 'right'"
												:filterOpen="filterOpen"
												:id-filter="key"
												:param="params"
												@query="getData"
												:fields="fields"
												:item="item"
											/>
										</p>
									</th>
								</template>
							</tr>
							<!-- /Заголовки -->
						</thead>

						<!-- Содержимое -->
						<tbody v-if="medOrgs.length > 0">
							<template v-for="(item, key) in medOrgs">
								<tr :key="item.medicalOrgId" @click="openRow(item)" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
									<td
										class="table-cell-ogv border-b sticky left-0 border-gray-100"
										:class="{
											'bg-gray-30': item.medicalOrgId === opened,
											'bg-white': item.medicalOrgId !== opened
										}"
									>
										<p class="custom-fs-12 flex items-center justify-center">
											<span v-if="params.currentPage === 0">
												{{ key + 1 }}
											</span>
											<span v-if="params.currentPage > 0">
												{{ params.currentPage * rowsVisible + (key + 1) }}
											</span>
										</p>
									</td>
									<td
										class="table-cell-ogv border-b border-gray-100 min-width sticky left-9 bg-white"
										:class="{ 'bg-gray-30': item.medicalOrgId === opened, 'cursor-pointer': item.hasBranch }"
									>
										<div class="flex items-center mb-2">
											<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
											<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
										</div>
										<p class="custom-fs-12" :class="{ underline: item.hasBranch }">{{ item.name }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.ambulatorCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.stacionarCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.sanatoriatCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-r border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.psychiatricCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.ambulatorPaperCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.stacionarPaperCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.sanatoriatPaperCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-r border-gray-100 text-center">
										<p class="custom-fs-12">{{ item.psychiatricPaperCount }}</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100">
										<p style="width: 150px; word-wrap: break-word" class="custom-fs-12">
											<a target="_blank" :href="item.webSiteAddress">{{ item.webSiteAddress ? item.webSiteAddress : '---' }}</a>
										</p>
									</td>
									<td class="table-cell-ogv border-b border-gray-100">
										<div class="flex items-center">
											<div class="icon pr-2">
												<svg v-if="item.siteResponseCode == 'Недоступен'" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_419_67593)">
														<path
															d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
															fill="#C10016"
														/>
														<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
													</g>
													<defs>
														<clipPath id="clip0_419_67593">
															<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
														</clipPath>
													</defs>
												</svg>
												<svg v-else width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_391_67823)">
														<path
															d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
															fill="#27AE60"
														/>
														<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
													</g>
													<defs>
														<clipPath id="clip0_391_67823">
															<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
														</clipPath>
													</defs>
												</svg>
											</div>
											<span>
												<span class="time">{{ item.siteLastUpdate }}</span>
											</span>
										</div>
									</td>
									<td class="table-cell-ogv border-b border-r border-gray-100">
										<div class="flex items-center justify-center">
											<svg v-if="!item.siteBannerType" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_419_67593)">
													<path
														d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
														fill="#C10016"
													/>
													<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												</g>
												<defs>
													<clipPath id="clip0_419_67593">
														<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
													</clipPath>
												</defs>
											</svg>
											<svg v-else width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clip-path="url(#clip0_391_67823)">
													<path
														d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
														fill="#27AE60"
													/>
													<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												</g>
												<defs>
													<clipPath id="clip0_391_67823">
														<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
													</clipPath>
												</defs>
											</svg>
										</div>
									</td>
								</tr>
								<template v-if="item.medicalOrgId === opened">
									<tr v-for="(subitem, ind1) in item.branches" :key="subitem.medicalOrgId" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
										<td class="table-cell-ogv border-b border-gray-10 sticky left-0 bg-gray-30">
											<p class="custom-fs-12 flex items-center justify-center">
												<span> {{ ind1 + 1 }} </span>
											</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-10 sticky left-9 bg-gray-30">
											<div class="flex items-center mb-2">
												<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
												<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
											</div>
											<p class="custom-fs-12">{{ subitem.name }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.ambulatorCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.stacionarCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.sanatoriatCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-r border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.psychiatricCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.ambulatorPaperCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.stacionarPaperCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.sanatoriatPaperCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-r border-gray-100 text-center">
											<p class="custom-fs-12">{{ subitem.psychiatricPaperCount }}</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100">
											<p style="width: 150px; word-wrap: break-word" class="custom-fs-12">
												<a target="_blank" :href="subitem.webSiteAddress">{{ subitem.webSiteAddress ? subitem.webSiteAddress : '---' }}</a>
											</p>
										</td>
										<td class="table-cell-ogv border-b border-gray-100">
											<div class="flex items-center">
												<div class="icon pr-2">
													<svg v-if="subitem.siteResponseCode == 'Недоступен'" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clip-path="url(#clip0_419_67593)">
															<path
																d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
																fill="#C10016"
															/>
															<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
															<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
														</g>
														<defs>
															<clipPath id="clip0_419_67593">
																<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
															</clipPath>
														</defs>
													</svg>
													<svg v-else width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clip-path="url(#clip0_391_67823)">
															<path
																d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
																fill="#27AE60"
															/>
															<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
														</g>
														<defs>
															<clipPath id="clip0_391_67823">
																<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
															</clipPath>
														</defs>
													</svg>
												</div>
												<span>
													<span class="time">{{ subitem.siteLastUpdate }}</span>
												</span>
											</div>
										</td>
										<td class="table-cell-ogv border-b border-r border-gray-100">
											<div class="flex items-center justify-center">
												<svg v-if="!subitem.siteBannerType" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_419_67593)">
														<path
															d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
															fill="#C10016"
														/>
														<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
													</g>
													<defs>
														<clipPath id="clip0_419_67593">
															<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
														</clipPath>
													</defs>
												</svg>
												<svg v-else width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clip-path="url(#clip0_391_67823)">
														<path
															d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
															fill="#27AE60"
														/>
														<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
													</g>
													<defs>
														<clipPath id="clip0_391_67823">
															<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
														</clipPath>
													</defs>
												</svg>
											</div>
										</td>
									</tr>
								</template>
							</template>
						</tbody>
					</table>
					<div v-if="!loadingTable && medOrgs.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
						<span class="w-full">Данных нет...</span>
					</div>
				</div>
				<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />

				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>

		<PopUp :open="openExport" size="500px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import PopUp from '@/components/PopUp.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'TableMain',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination,
		Icons,
		CheckboxGroup,
		PopUp
	},
	computed: {
		...getters
	},
	data() {
		return {
			openExport: false,
			loadToExcell: false,
			loadingTable: false,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					autocomplete: true,
					list: [],
					itemKey: 'name',
					widthStroke: 'w-244',
					heightUl: '180px',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorCount',
					filterType: 1,
					autocomplete: false,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarCount',
					filterType: 1,
					autocomplete: false,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatCount',
					filterType: 1,
					autocomplete: false,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricCount',
					autocomplete: false,
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorPaperCount',
					filterType: 1,
					autocomplete: false,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarPaperCount',
					filterType: 1,
					autocomplete: false,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatPaperCount',
					autocomplete: false,
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricPaperCount',
					filterType: 1,
					filterValueType: 2,
					autocomplete: false,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Адрес сайта',
					fieldName: 'webSiteAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					autocomplete: false,
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Доступность сайта',
					fieldName: 'siteLastUpdate',
					autocomplete: false,
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По доступности (Да–Нет)',
					label2: 'По доступности (Нет–Да)'
				},
				{
					Name: 'Наличие баннера',
					fieldName: 'siteBannerType',
					filterType: 1,
					filterValueType: 3,
					value1: '',
					value2: null,
					autocomplete: false,
					sortBy: null,
					label1: 'По наличию (Нет–Да)',
					label2: 'По наличию (Да–Нет)'
				}
			],
			fieldsDownloadExcell: [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'Name', checked: true },
				{ title: 'Анкеты (интерактивные) Амбулаторные', id: 'AmbulatorCount', checked: true },
				{ title: 'Анкеты (интерактивные) Стационарные', id: 'StacionarCount', checked: true },
				{ title: 'Анкеты (интерактивные) Санаторно-курортные', id: 'SanatoriatCount', checked: true },
				{ title: 'Анкеты (интерактивные) Психиатрические', id: 'PsychiatricCount', checked: true },
				{ title: 'Анкеты (бумажные) Амбулаторные', id: 'AmbulatorPaperCount', checked: true },
				{ title: 'Анкеты (бумажные) Стационарные', id: 'StacionarPaperCount', checked: true },
				{ title: 'Анкеты (бумажные) Санаторно-курортные', id: 'SanatoriatPaperCount', checked: true },
				{ title: 'Анкеты (бумажные) Психиатрические', id: 'PsychiatricPaperCount', checked: true },
				{ title: 'Адрес сайта', id: 'WebSiteAddress', checked: true },
				{ title: 'Доступность сайта', id: 'SiteResponseCode', checked: true },
				{ title: 'Дата проверки', id: 'SiteLastUpdate', checked: true },
				{ title: 'Наличие баннера', id: 'SiteBannerType', checked: true }
			],
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			allRows: [],
			medOrgs: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: '1038b985-5708-41ef-95cb-85ba7586702c'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				noteid: '150a6a2c-e499-435d-9c2c-8488e60f5555',
				pageSize: this.$MaxPageSize,
				currentPage: 0,
				columns: []
			},
			opened: null
		};
	},
	methods: {
		...mutations,
		...actions,
		...localActions,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getMedicalOrgsRates(this.params).then((res) => {
					this.allRows = res.data.data;
					this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;

					for (let i = 0; i < this.params.filters.length; i += 1) {
						if (this.params.filters[i].fieldName === 'siteLastUpdate') {
							if (this.params.filters[i].value1 && this.params.filters[i].value2) {
								let splitVal1 = this.params.filters[i].value1.split('-'),
									splitVal2 = this.params.filters[i].value2.split('-');

								this.params.filters[i].value1 = `${splitVal1[2]}-${splitVal1[1]}-${splitVal1[0]}`;
								this.params.filters[i].value2 = `${splitVal2[2]}-${splitVal2[1]}-${splitVal2[0]}`;
							}
						}
					}

					this.loadingTable = false;
				});
			} else {
				this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		setName(id, type) {
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.name;
			});
			return name;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetOGVExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Главная страница.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	top: 0;
	position: sticky;
	z-index: 10;
}
.tr2__sticky {
	top: 60px;
	position: sticky;
	z-index: 0;
}
.table-cell {
	@apply flex flex-wrap text-sm py-2 text-left my-auto text-center;
}
li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.table-cell_center {
	@apply flex flex-wrap text-sm py-2 text-left;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
}

.custom-input:not(:checked) {
	display: none;
}
.min-width {
	min-width: 300px;
}
.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}

.item:hover svg path {
	stroke: #fff;
}

.item:hover {
	color: #fff;
}

svg {
	transition: 0.3s;
}

.trow .active svg {
	transform: rotate(180deg);
}

.trow .active path {
	stroke: #fff;
}

.th {
	padding-left: 30px;
	align-items: center;
	position: relative;
}

.th-content:first-child {
	min-width: 30%;
}

.th-content .table-cell,
.table-cell-ogv {
	padding: 10px;
}

.table-cell-ogv span {
	margin: auto 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-cell-ogv span.m-auto {
	margin: auto;
}

.th-content-type {
	padding: 20px;
	line-height: 20px;
}

.type-1 {
	width: 800px;
	max-width: 800px;
	min-width: 800px;
}

.type-2 {
	width: 600px;
	max-width: 600px;
	min-width: 600px;
}

.th-content-type:first-child {
	margin-left: 30%;
}

.td {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #6a6e83;
	min-height: 100px;
	padding: 20px 30px;
}

.table-cell-ogv:first-child {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #6a6e83;
	padding: 5px;
}
.first {
	min-width: 400px !important;
	width: 30% !important;
}
.custom-fs-12 {
	font-size: 12px;
}
</style>
